import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import clsx from 'clsx';

import {
  fontWeight,
  Heading,
  headingLevel,
  Text,
  textLevel,
} from '@confidant-health/lib/ui/atoms/typography';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Badge, badgeType, badgeStyle } from '@confidant-health/lib/ui/atoms/badge';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import RestoreIcon from '@mui/icons-material/Restore';
import StepOne from 'pages/admin/appointments/add-schedule/step-one/StepOne';

import { showSnackbar } from 'redux/modules/snackbar';
import { getProfile } from 'redux/modules/profile/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { IDemographicDetails, IProvider } from 'redux/modules/profile/types';
import { getMatchmakers, suggestConnection } from 'services/member/member.service';
import { profileActionCreators } from 'redux/modules/profile';
import CreateConnectionModal from 'layouts/base/CreateConnectionModal';

import EmptyState from '../EmptyState';
import { ICareTeam, IMatchmaker } from '../../MemberDetail.types';
import { useStyles } from '../../MemberDetail.styles';
import UpdateVisitFrequency from './edit';

declare global {
  interface Window {
    branch: any;
  }
}

const CareTeam: React.FC = () => {
  const classes = useStyles({});
  const { memberId } = useParams();
  const dispatch = useDispatch();
  const {
    demographicDetails = {} as IDemographicDetails,
    profile,
    providers,
    careTeamList2,
  } = useSelector(getProfile);
  const { isAdmin } = useSelector(getAuth);
  const [careTeamItems, setCareTeamItems] = useState<ICareTeam[]>([]);
  const [matchmakers, setMatchmakers] = useState<IMatchmaker[]>([]);
  const [savedMatchmakers, setSavedMatchmakers] = useState<ICareTeam[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRecommendProvider, setShowRecommendProvider] = useState(false);
  const [isCreateConnection, setIsCreateConnection] = useState(false);
  const [selectedCareTeam, setSelectedCareTeam] = useState(null);
  const [showMatchmakers, setShowMatchmakers] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<IProvider>(null);
  const [error, setError] = useState(null);
  const [openUpdateFrequncyEdit, setOpenUpdateFrequncyEdit] = useState<boolean>(false);
  const [frequencyDataObj, setFrequencyDataObj] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [filteredProviders, setFilteredProviders] = useState(showRecommendProvider ? providers : matchmakers);
  const onCloseSlider = () => {
    setShowMatchmakers(false);
    setShowRecommendProvider(false);
  };

  const onToggleRecommendedProviderSlide = () => {
    setShowRecommendProvider(s => !s);
  };

  const onToggleMatchmakersSlide = () => {
    setShowMatchmakers(s => !s);
  };

  const onSelectedProvider = item => {
    setError(null);
    setSelectedProvider(item);
  };
  const appointmentFrequency = item => {
    return (
      <div className={classes.frequency}>
        <div
          className={`${classes.section} ${
            item === 'Daily'
              ? classes.daily
              : item === 'Weekly'
              ? classes.weekly
              : item === 'Biweekly'
              ? classes.biweekly
              : item === 'Monthly'
              ? classes.monthly
              : item === 'As needed'
              ? classes.asNeeded
              : item === 'No longer in care'
              ? classes.noLongerInCare
              : ''
          }`}
        >
          <RestoreIcon
            className={`${classes.iconSize} ${
              item === 'Daily'
                ? classes.dailyIcon
                : item === 'Weekly'
                ? classes.weeklyIcon
                : item === 'Biweekly'
                ? classes.biweeklyIcon
                : item === 'Monthly'
                ? classes.monthlyIcon
                : item === 'As needed'
                ? classes.asNeededIcon
                : item === 'No longer in care'
                ? classes.noLongerInCareIcon
                : ''
            }`}
          />
          <div className={classes.appointFreqText}>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.colLabel}>
              Appointments Frequency
            </Text>
            <Text level={textLevel.S} weight={fontWeight.MEDIUM} className={classes.colLabel2}>
              {item}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  /*   const fetchCareTeam = useCallback(() => {
    try {
      // const { data: careTeamList } = await getCareTeamMember({ userId: memberId });
      setCareTeamItems(careTeamList2.filter(item => item.type !== 'MATCH_MAKER'));
      setSavedMatchmakers(careTeamList2.filter(item => item.type === 'MATCH_MAKER'));
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
    setIsLoading(false);
  }, [memberId, careTeamList2]);
 */
  const fetchMatchmakers = useCallback(async () => {
    try {
      const { data: matchmakerList } = await getMatchmakers();
      setMatchmakers(
        matchmakerList.map(item => ({
          ...item,
          id: item.userId,
          providerId: item.userId,
          designation: 'Matchmaker',
        })),
      );
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
    setIsLoading(false);
  }, [memberId]);

  const onSubmitProvider = async () => {
    if (!selectedProvider) {
      setError(showRecommendProvider ? 'Please select a provider' : 'Please select a matchmaker');
      return;
    }

    const { providerId } = selectedProvider;

    try {
      await suggestConnection({
        suggestedFirstUser: memberId,
        suggestedSecondUser: providerId,
      });
      dispatch(
        showSnackbar({
          snackType: 'success',
          snackMessage: 'Recommended successfully!',
        }),
      );
      onCloseSlider();
      dispatch(profileActionCreators.fetchCareTeam({ userId: memberId }));
      // void fetchCareTeam();
    } catch (err) {
      const msg = err.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
      dispatch(
        showSnackbar({
          snackType: 'error',
          snackMessage: msg,
        }),
      );
    }
  };

  const deleteConnection = () => {
    dispatch(
      profileActionCreators.removeConnections({ memberId, providerId: selectedCareTeam?.connectionId }),
    );
    setTimeout(() => {
      setIsCreateConnection(false);
      void fetchMatchmakers();
      dispatch(profileActionCreators.fetchCareTeam({ userId: memberId }));
    }, 1000);
  };

  useEffect(() => {
    if (memberId) {
      void fetchMatchmakers();
      dispatch(profileActionCreators.fetchCareTeam({ userId: memberId }));
      // void fetchCareTeam();
    }
    dispatch(
      profileActionCreators.fetchProviders({
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: 'asc',
        sortBy: '',
      }),
    );
  }, []);

  useEffect(() => {
    if (careTeamList2) {
      setCareTeamItems(careTeamList2.filter(item => item.type !== 'MATCH_MAKER'));
      setSavedMatchmakers(careTeamList2.filter(item => item.type === 'MATCH_MAKER'));
      // setIsLoading(false);
    }
  }, [careTeamList2]);
  useEffect(() => {
    if (!searchKey) {
      setFilteredProviders(showRecommendProvider ? providers : matchmakers);
      return;
    }
    if (showRecommendProvider) {
      setFilteredProviders(
        providers
          .filter(
            item =>
              item?.fullName?.toLocaleLowerCase()?.includes(searchKey) ||
              item?.firstName?.toLocaleLowerCase()?.includes(searchKey) ||
              item?.lastName?.toLocaleLowerCase()?.includes(searchKey),
          )
          ?.filter(providr => providr.active) || providers?.filter(providr => providr.active),
      );
      return;
    }
    setFilteredProviders(
      matchmakers
        .filter(
          item =>
            item?.fullName?.toLocaleLowerCase()?.includes(searchKey) ||
            item?.firstName?.toLocaleLowerCase()?.includes(searchKey) ||
            item?.lastName?.toLocaleLowerCase()?.includes(searchKey),
        )
        ?.filter(providr => providr.active) || matchmakers?.filter(providr => providr.active),
    );
  }, [searchKey, showRecommendProvider]);

  const renderMatchMakerInfo = (item: ICareTeam, index: number) => {
    return (
      <div key={index} className={classes.careTeamUserCard}>
        <div className={classes.gridSection}>
          <div className={classes.avatarWrapper}>
            <Avatar variant={avatarType.CIRCLE} size="48px" src={item.profilePicture} name={item.name} />
            <div>
              <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
                {item.name}
              </Text>
              <Badge
                className={clsx(classes.badge, 'Matchmaker')}
                style={badgeStyle.MEDIUM}
                variant={badgeType.FILLED}
              >
                Matchmaker
              </Badge>
            </div>
          </div>
          <div className={classes.gridSection2}>
            {item.visitFrequency && item.visitFrequency !== 'None' && (
              <>{appointmentFrequency(item.visitFrequency)}</>
            )}
            {/* {nextAppointment(item.nextAppointment)} */}
          </div>
        </div>
        <div className={classes.gotoChat2}>
          {isAdmin && (
            <Button
              variant="outlined"
              className={classes.careTeamDeleteBtn}
              onClick={() => {
                setSelectedCareTeam(item);
                setIsCreateConnection(true);
              }}
            >
              <Icons glyph="delete-outlined-2" color={colors.destructive600} />
            </Button>
          )}
          {!isAdmin && item.connectionId !== profile.providerId && (
            <Button
              variant="outlined"
              className={classes.careTeamBtn}
              // className={clsx(classes.careTeamValueWrapper, classes.link)}
              href={`/provider/chats?userId=${item.connectionId}&type=providers`}
            >
              <Icons glyph="chat-square" color={colors.primary600} />
            </Button>
          )}
          <Button
            variant="outlined"
            className={classes.careTeamBtn}
            onClick={() => {
              setOpenUpdateFrequncyEdit(true);
              setFrequencyDataObj({
                userId: item.connectionId,
                name: item.name,
                visitFrequency: item.visitFrequency,
              });
            }}
          >
            <Icons glyph="edit-underline" color={colors.primary600} />
          </Button>
        </div>
      </div>
    );
  };

  const renderCareTeamInfo = (item: ICareTeam, index: number) => {
    return (
      <div key={index} className={classes.careTeamUserCard}>
        <div className={classes.gridSection}>
          <div className={classes.avatarWrapper}>
            <Avatar variant={avatarType.CIRCLE} size={48} src={item.profilePicture} name={item.name} />
            <div>
              <Text level={textLevel.M} weight={fontWeight.SEMI_BOLD}>
                {item.name}
              </Text>
              <Badge
                className={clsx(classes.badge, item.designation)}
                style={badgeStyle[item.designation.toUpperCase()] || badgeStyle.UNRELATED}
                variant={badgeType.FILLED}
              >
                {item.designation}
              </Badge>
            </div>
          </div>
          <div className={classes.gridSection2}>
            {item.visitFrequency && item.visitFrequency !== 'None' && (
              <>{appointmentFrequency(item.visitFrequency)}</>
            )}
            {/* {nextAppointment(item.nextAppointment)} */}
          </div>
        </div>
        <div className={classes.gotoChat2}>
          {isAdmin && (
            <Button
              variant="outlined"
              className={classes.careTeamDeleteBtn}
              onClick={() => {
                setSelectedCareTeam(item);
                setIsCreateConnection(true);
              }}
            >
              <Icons glyph="delete-outlined-2" color={colors.destructive600} />
            </Button>
          )}
          {!isAdmin && item.connectionId !== profile.providerId && (
            <Button
              variant="outlined"
              className={classes.careTeamBtn}
              // className={clsx(classes.careTeamValueWrapper, classes.link)}
              href={`/provider/chats?userId=${item.connectionId}&type=providers`}
            >
              <Icons glyph="chat-square" color={colors.primary600} />
            </Button>
          )}
          <Button
            variant="outlined"
            className={classes.careTeamBtn}
            onClick={() => {
              setOpenUpdateFrequncyEdit(true);
              setFrequencyDataObj({
                userId: item.connectionId,
                name: item.name,
                visitFrequency: item.visitFrequency,
              });
            }}
          >
            <Icons glyph="edit-underline" color={colors.primary600} />
          </Button>
        </div>
      </div>
    );
  };

  const renderSection = (label: string, className = '') => (
    <Heading
      className={clsx(classes.subHeading, className)}
      weight={fontWeight.SEMI_BOLD}
      level={headingLevel.L}
    >
      {label}
    </Heading>
  );

  return (
    <>
      <Drawer
        open={showRecommendProvider || showMatchmakers}
        onClose={onCloseSlider}
        variant={drawerType.FORM}
        title={showRecommendProvider ? 'Recommend provider' : 'Recommend matchmaker'}
        submitBtnTitle="Recommend"
        onSubmit={onSubmitProvider}
        className={classes.recommendSlider}
      >
        <StepOne
          selectedItem={selectedProvider}
          onChange={onSelectedProvider}
          error={error}
          options={filteredProviders}
          selectType="provider"
          className="select-provider"
          showSimpleSearch
          hidePrimarySearch
          setSimpleSearchKey={setSearchKey}
        />
      </Drawer>
      <div className={classes.headingWithBtnWrapper}>
        <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
          Care Team
        </Heading>
        <IconButton
          className={classes.addBtn}
          icon="plus"
          variant={iconBtnType.PRIMARY}
          onClick={() => {
            setSearchKey('');
            onToggleRecommendedProviderSlide();
          }}
        >
          Add new
        </IconButton>
      </div>
      {isLoading ? (
        <Box display="flex" justifyContent="center" sx={{ paddingY: 5 }}>
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <div className={classes.careTeamCardContainer}>
          {savedMatchmakers.length > 0 ? (
            <div className={classes.careTeamCardsWrapper}>
              {renderSection('Matchmaker')}
              {savedMatchmakers.map(renderMatchMakerInfo)}
            </div>
          ) : (
            <EmptyState
              title="Matchmaker"
              subtitle={`${demographicDetails.nickName} don’t have a matchmaker yet.`}
              btnLabel="Assign matchmaker"
              btnClick={onToggleMatchmakersSlide}
            />
          )}
          {careTeamItems?.length > 0 ? (
            <div className={classes.careTeamCardsWrapper}>
              {renderSection('Care team connections', 'care-team')}
              {careTeamItems.map(renderCareTeamInfo)}
            </div>
          ) : (
            <EmptyState
              title="There are no other care team members for this member yet."
              subtitle=""
              btnLabel="Recommend provider"
              btnClick={onToggleRecommendedProviderSlide}
              glyph="lottie-care-team"
              isEmptyWithLottie
              lottieSize={80}
            />
          )}
        </div>
      )}
      {openUpdateFrequncyEdit && (
        <UpdateVisitFrequency
          openDrawer={openUpdateFrequncyEdit}
          onClose={() => setOpenUpdateFrequncyEdit(false)}
          data={frequencyDataObj}
          currentUserId={memberId}
        />
      )}
      {isCreateConnection && (
        <CreateConnectionModal
          icon="x-circle-outlined"
          open={isCreateConnection}
          title="Delete Connection"
          content={`Do you want to remove ${selectedCareTeam?.name} from care team?`}
          lbtnLabel="Cancel"
          rbtnLabel="Confirm"
          onClose={() => setIsCreateConnection(false)}
          onCancel={() => setIsCreateConnection(false)}
          onSubmit={deleteConnection}
          isLoading={false}
          isDelete
        />
      )}
    </>
  );
};

export default CareTeam;
