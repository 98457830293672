import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from '@confidant-health/lib/icons';
import { colors } from '@confidant-health/lib/colors';

import {
  ProfileSectionIds,
  certificationsMock,
  credentialsMock,
} from 'pages/provider/profile/Profile.constants';
import ProfileCard from 'pages/provider/profile/components/profile-card';
import EditCredentials from 'pages/provider/profile/components/credentials';
import EditGeneralInfo from 'pages/provider/profile/components/general-info';
import EditApproach from 'pages/provider/profile/components/approach';
import EditClinicAffiliation from 'pages/provider/profile/components/clinic-affiliation';
import EditEducation from 'pages/provider/profile/components/education';
import EditAvailableStates from 'pages/provider/profile/components/available-states';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderServicesState } from 'redux/modules/appointment/selectors';
import { profileActionCreators } from 'redux/modules/profile';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { profileSections } from './GeneralInfo.mock';
import { stateActionCreators } from '../../../../redux/modules/state';
import { ResetPassword } from '../reset-password/ResetPassword';
import { getAuth } from '../../../../redux/modules/auth/selectors';
import ScheduleLink from '../../../provider/schedule/components/schedule-link/ScheduleLink';
import Header from '../../../../components/v2/Header';

const GeneralInfo: FC = () => {
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(getAuth);
  const { services } = useSelector(selectProviderServicesState);
  const { providerDetails } = useSelector(getProfile);
  const [editSection, setEditSection] = useState<ProfileSectionIds | null>(null);
  const [sections, setSections] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const providerSchedule = useSelector(selectProviderSchedule);
  const onEdit = (sectionId: ProfileSectionIds) => () => {
    setErrorMsg(null);
    setEditSection(sectionId);
  };
  const onCloseEdit = () => {
    setEditSection(null);
  };

  useEffect(() => {
    dispatch(stateActionCreators.fetchPayerGroups());
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const getContent = (id: string) => {
    switch (id) {
      case ProfileSectionIds.general: {
        return {
          description: providerDetails?.bio || '',
          profile: {
            avatar: providerDetails?.profileImage || '',
            name: providerDetails?.fullName || '',
            firstName: providerDetails?.firstName || '',
            lastName: providerDetails?.lastName || '',
            role: providerDetails?.providerProfile?.providerRole || '',
            activeInApp: providerDetails?.active,
            acceptingNewPatients: providerDetails?.acceptingNewPatients,
            specialities: providerDetails?.speciality,
            emailAddress: providerDetails?.emailAddress,
            phoneNumber: providerDetails?.phoneNumber,
            twoFAEnabled: providerDetails?.twoFAEnabled,
            designation: providerDetails?.designation,
            signOffRole: providerDetails?.signOffRole || '',
            npi: providerDetails?.npi || '',
            acceptingAdolescents: providerDetails?.acceptingAdolescents || false,
            taxId: providerDetails?.taxId || '',
            billingProvider: providerDetails?.billingProvider,
            providerAvailabilityCalendar: providerDetails?.providerAvailabilityCalendar,
            practitionerAddress: {
              state: providerDetails?.practitionerAddress?.state || '',
              city: providerDetails?.practitionerAddress?.city || '',
              zipCode: providerDetails?.practitionerAddress?.zipCode || '',
              zipPlusFourCode: providerDetails?.practitionerAddress?.zipPlusFourCode || '',
              address1: providerDetails?.practitionerAddress?.address1 || '',
            },
          },
        };
      }
      case ProfileSectionIds.approach: {
        return {
          description: providerDetails?.approach || 'N/A',
        };
      }
      case ProfileSectionIds.availableStates: {
        return {
          insurances: providerDetails?.insurances?.sort((a, b) => (a > b ? 1 : -1)) || [],
        };
      }
      case ProfileSectionIds.certifications: {
        return {
          badges: providerDetails?.providerProfile?.certifications?.sort((a, b) => (a > b ? 1 : -1)) || [],
        };
      }
      case ProfileSectionIds?.credentials: {
        return {
          badges: providerDetails?.providerProfile?.credentials?.sort((a, b) => (a > b ? 1 : -1)) || [],
        };
      }
      case ProfileSectionIds?.clinicAffiliation: {
        return {
          affiliation: {
            avatar: providerDetails?.providerProfile?.affiliationImage,
            title: providerDetails?.providerProfile?.affiliationName?.[0],
            description: providerDetails?.providerProfile?.affiliationPlace?.[0],
            startDate: providerDetails?.providerProfile?.affiliationStartDate,
            endDate: providerDetails?.providerProfile?.affiliationEndDate,
          },
        };
      }
      case ProfileSectionIds.pastEmployment: {
        return {
          affiliation: {
            avatar: providerDetails?.providerProfile?.employmentImage,
            title: providerDetails?.providerProfile?.employmentName?.[0],
            description: providerDetails?.providerProfile?.employmentPlace?.[0],
            startDate: providerDetails?.providerProfile?.employmentStartDate,
            endDate: providerDetails?.providerProfile?.employmentEndDate,
          },
        };
      }
      case ProfileSectionIds?.education: {
        return {
          affiliation: {
            avatar: providerDetails?.providerProfile?.educationImage,
            title: providerDetails?.providerProfile?.educationName?.[0],
            description: providerDetails?.providerProfile?.educationDescription,
            startDate: providerDetails?.providerProfile?.educationStartDate,
            endDate: providerDetails?.providerProfile?.educationEndDate,
          },
        };
      }
      case ProfileSectionIds.generalAvailability: {
        return {
          workingDays: {
            sunday: null,
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
          },
        };
      }
      case ProfileSectionIds.resetPassword: {
        return {
          emailAddress: providerDetails?.emailAddress,
        };
      }
      default:
        return { services };
    }
  };

  // const formatPlanningHorizon = (pHorizon: any[]) => {
  //   const formatedPlanningHorizon = {};
  //   pHorizon.forEach(ph => {
  //     formatedPlanningHorizon[ph.day.toLowerCase()] = ph;
  //   });
  //   return formatedPlanningHorizon;
  // };
  const onUpdateSuccess = () => {
    setIsSaving(false);
    onCloseEdit();
  };
  const onSubmit = (payload: any) => {
    try {
      setErrorMsg(null);
      const isActivePayload: boolean = payload?.active
        ? JSON.parse(payload?.active)
        : providerDetails?.active || false;
      const acceptingNewPatientsPayload: boolean = payload?.acceptingNewPatients
        ? JSON.parse(payload?.acceptingNewPatients)
        : providerDetails?.acceptingNewPatients || false;
      const data = {
        affiliationImage: payload?.affiliationImage || providerDetails?.providerProfile?.affiliationImage,
        affiliationName: payload?.affiliationName || providerDetails?.providerProfile?.affiliationName,
        affiliationPlace: payload?.affiliationPlace || providerDetails?.providerProfile?.affiliationPlace,
        approach: payload?.approach || providerDetails?.approach,
        certifications: payload?.certifications || providerDetails?.providerProfile?.certifications,
        credentials: payload?.credentials || providerDetails?.providerProfile?.credentials,
        educationDescription:
          payload?.educationDescription || providerDetails?.providerProfile?.educationDescription,
        educationEndDate: payload?.educationEndDate || providerDetails?.providerProfile?.educationEndDate,
        profilePicture: providerDetails?.profileImage,
        educationImage: payload?.educationImage || providerDetails?.providerProfile?.educationImage,
        educationName: payload?.educationName || providerDetails?.providerProfile?.educationName,
        educationPlace: payload?.educationPlace || providerDetails?.providerProfile?.educationPlace,
        educationStartDate:
          payload?.educationStartDate || providerDetails?.providerProfile?.educationStartDate,
        employmentEndDate: payload?.employmentEndDate || providerDetails?.providerProfile?.employmentEndDate,
        employmentImage: payload?.employmentImage || providerDetails?.providerProfile?.employmentImage,
        employmentName: payload?.employmentName || providerDetails?.providerProfile?.employmentName,
        employmentPlace: payload?.employmentPlace || providerDetails?.providerProfile?.employmentPlace,
        employmentStartDate:
          payload?.employmentStartDate || providerDetails?.providerProfile?.employmentStartDate,
        firstName: payload?.firstName || providerDetails?.firstName,
        lastName: payload?.lastName || providerDetails?.lastName,
        fullName: payload?.fullName || providerDetails?.fullName,
        active: payload?.active !== null ? isActivePayload : providerDetails?.active,
        acceptingNewPatients:
          payload?.acceptingNewPatients !== null
            ? acceptingNewPatientsPayload
            : providerDetails?.acceptingNewPatients,
        bio: payload?.bio,
        providerRole: payload?.providerRole || providerDetails?.providerProfile?.providerRole,
        providerAvailabilityCalendar: payload?.providerAvailabilityCalendar || '',
        twoFAEnabled: payload?.twoFAEnabled !== null ? payload?.twoFAEnabled : providerDetails?.twoFAEnabled,
        speciality: payload?.speciality?.length > 0 ? payload?.speciality : providerDetails?.speciality,
        phoneNumber: payload?.phoneNumber !== null ? payload?.phoneNumber : providerDetails?.phoneNumber,
        signOffRole: payload?.signOffRole || providerDetails?.signOffRole,
        designation: payload?.designation !== null ? payload?.designation : providerDetails?.designation,
        npi: payload?.npi !== null ? payload?.npi : providerDetails?.npi,
        acceptingAdolescents:
          payload?.acceptingAdolescents !== null
            ? payload.acceptingAdolescents
            : providerDetails?.acceptingAdolescents,
        taxId: payload?.taxId ?? providerDetails.taxId,
        billingProvider:
          payload?.billingProvider !== null ? payload.billingProvider : providerDetails?.billingProvider,
        practitionerAddress: {
          state: payload?.state ?? providerDetails?.practitionerAddress?.state,
          city: payload?.city ?? providerDetails?.practitionerAddress?.city,
          zipCode: payload?.zipCode ?? providerDetails?.practitionerAddress?.zipCode,
          zipPlusFourCode: payload?.zipPlusFourCode ?? providerDetails?.practitionerAddress?.zipPlusFourCode,
          address1: payload?.address1 ?? providerDetails?.practitionerAddress?.address1,
        },
      };
      if (payload?.timezone !== providerSchedule?.timezone) {
        dispatch(
          scheduleActionCreators.updateProviderSchedule({
            providerId: providerDetails?.providerId,
            data: {
              timezone: payload?.timezone || providerSchedule?.timezone,
            },
          }),
        );
      }
      dispatch(
        profileActionCreators.updateProviderGeneralInfo({
          id: providerDetails?.providerId,
          data,
          onSuccess: onUpdateSuccess,
        }),
      );
    } catch (err) {
      setErrorMsg(err.data?.errors?.[0]?.endUserMessage || 'Unable to update data');
      setIsSaving(false);
    }
  };

  const editContent = getContent(editSection);

  useEffect(() => {
    let newSections;
    if (isAdmin) {
      newSections = profileSections?.map(item => ({
        ...item,
        content: getContent(item.id),
      }));
    } else {
      newSections = profileSections
        ?.filter(
          section => section.id === ProfileSectionIds.general || section.id === ProfileSectionIds.approach,
        )
        ?.map(item => ({
          ...item,
          content: getContent(item.id),
        }));
    }
    setSections(newSections);
  }, [providerDetails, services]);
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" paddingY="10px">
          <Icons className="rotate linear infinite" glyph="in-progress" color={colors.primary} />
        </Box>
      ) : (
        <>
          {!isAdmin && <Header label="General information" />}
          <Box display="flex" flexDirection="column" gap="16px">
            {!isAdmin && <ScheduleLink />}
            {sections.map((section, idx) => (
              <ProfileCard {...section} key={idx} onEdit={onEdit(section.id)} />
            ))}
          </Box>
        </>
      )}
      <EditGeneralInfo
        isOpen={editSection === ProfileSectionIds.general}
        onClose={onCloseEdit}
        error={errorMsg}
        profile={
          {
            description: editContent?.description,
            ...editContent?.profile,
            timezone: (providerSchedule && providerSchedule.timezone) || '',
          } as any
        }
        onSubmit={onSubmit}
      />
      <EditApproach
        isOpen={editSection === ProfileSectionIds.approach}
        onClose={onCloseEdit}
        error={errorMsg}
        onSubmit={onSubmit}
        description={editContent.description}
      />
      <EditAvailableStates
        isOpen={editSection === ProfileSectionIds.availableStates}
        onClose={onCloseEdit}
        providerId={providerDetails?.providerId}
        states={editContent.badges}
      />
      <EditCredentials
        type={editSection}
        title={editSection === ProfileSectionIds.certifications ? 'Certifications' : 'Credentials'}
        isOpen={[ProfileSectionIds.credentials, ProfileSectionIds.certifications].includes(editSection)}
        onClose={onCloseEdit}
        error={errorMsg}
        selectedItems={editContent.badges}
        items={editSection === ProfileSectionIds.certifications ? certificationsMock : credentialsMock}
        onUpdate={onSubmit}
      />
      <EditEducation
        isOpen={editSection === ProfileSectionIds.pastEmployment}
        onClose={onCloseEdit}
        error={errorMsg}
        onUpdate={onSubmit}
        data={editContent.affiliation}
      />
      <EditEducation
        isOpen={editSection === ProfileSectionIds.education}
        onClose={onCloseEdit}
        isEducation
        onUpdate={onSubmit}
        error={errorMsg}
        data={editContent.affiliation}
      />
      <EditClinicAffiliation
        isOpen={editSection === ProfileSectionIds.clinicAffiliation}
        onClose={onCloseEdit}
        error={errorMsg}
        onUpdate={onSubmit}
        clinicAffiliation={editContent.affiliation}
      />

      <ResetPassword
        isOpen={editSection === ProfileSectionIds.resetPassword}
        onClose={onCloseEdit}
        email={editContent.emailAddress}
      />
    </>
  );
};

export { GeneralInfo };
